import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from "ant-design-vue";
import api from "@/api";
import store from "@/vuex";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// import './plugins/element.js'
import 'babel-polyfill'
import 'ant-design-vue/dist/antd.css';
import './css/override-element-ui.css'

import * as echarts from 'echarts';

import func from './plugins/preload.js'

// import taskNodeWrap from '@/components/task/NodeWrap'
// import taskAddNode from '@/components/task/AddNode'
// Vue.component('taskNodeWrap', taskNodeWrap);
// Vue.component('taskAddNode', taskAddNode);

Vue.use(api);
Vue.use(Antd);
Vue.use(VueCodemirror)
Vue.use(ElementUI, {
    size: "60",
    zIndex: 2000
});

Vue.prototype.$echarts = echarts
Vue.prototype.$func = func;
Vue.config.productionTip = false;

Vue.directive('enterNumber', {
    bind: function (el, { value = 2 }) {
        el = el.nodeName == "INPUT" ? el : el.children[0]
        var RegStr = value == 0 ? `^[\\+\\-]?\\d+\\d{0,0}` : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`;
        el.addEventListener('keyup', function () {
            el.value = el.value.match(new RegExp(RegStr, 'g'));
            el.dispatchEvent(new Event('input'))
        })
    }
});

Vue.directive('focus', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el) {
        el.focus()
    }
});

Vue.prototype.$bus = new Vue();

// Vue.prototype.$message = {
//     error(msg) {
//         ElementUI.Message.error(msg)
//     },
//     success(msg) {
//         ElementUI.Message.success(msg)
//     }
// }

new Vue({
    router,
    store,
    components: {
        // taskNodeWrap,
        // taskAddNode,
    },
    render: h => h(App)
}).$mount('#app')