import * as http from '@/api/axios';


/**
 * 飞书登录
 * @param {*} params 
 * @returns 
 */
export function login(id) {
    const url = '/login';
    return http.$post(url, {
        code: id,
        appid: 'cli_a614a7fbbb7bd013',
        platform: 'webpass'
    });
}


/**
 * jsapi 鉴权
 * @param {*} params 
 * @returns 
 */
export function jsapi(data) {
    return http.$post('/admin/auth/user/jsapi', data);
}



/**
 * 聊天
 * @param {*} params 
 * @returns 
 */
export function chat(platform, value) {
    return http.$post(`/ai/chat/${platform}/`, {
        _method: scene,
        _value: value,
    });
}




