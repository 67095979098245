import Vue from "vue";
import axios from "axios";
import store from "@/vuex";
import router from '@/router';
import { domain } from "@/env";
import { Message } from 'element-ui';




// axios.defaults.baseURL = '/api';
// axios.defaults.headers["Authorization"] = store.state.token;
axios.defaults.headers["Content-Type"]  = "application/json";


axios.interceptors.response.use(
    response => {
        const code = response.data.code || 0;
        switch (code) {
            case 200:
                return Promise.resolve(response);
            case 1001:
            case 305:
            case 302:
            case 401:
                window.localStorage.removeItem('uinfo');
                //Message.error('请重新登录');
                router.push({ name: 'login' });
                return Promise.reject(response);
            default:
                console.log(code);
                return Promise.resolve(response);
                //return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 404:
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error.response);
    }
);


export function $post(url, data) {
    let that = this;
    return new Promise((resolve, reject) => {
        // qs.stringify()将对象序列化成URL的形式，以&进行拼接
        axios.post('/api' + url, data, {
            headers: {
                Authorization: store.state.token
            }
        }).then(res => {
            if (res.data.code == 200) {
                resolve(res.data);
            } else {
                res.data.message && Message.error(res.data.message);
                resolve(res.data);
            }
        })
        .catch(function (res) {
            let message = res.data.message || '';
            message && Message.error(message);
            resolve(res.data);
        });
    });
}

