import * as http from '@/api/axios';


/**
 * 聊天
 * @param {*} params 
 * @returns 
 */
export function chat(platform, model, messages) {
    return http.$post('/ai/chat', {
        platform: platform,
        model: model,
        messages: messages,
    });
}




