import * as app from './module/app.js';
import * as ai from './module/ai.js';
    


let api = {
    ai: ai,
    app: app,
};



const install = Vue => {
    if (install.installed) {
		return;
	}

    install.installed = true;
    Object.defineProperties(Vue.prototype, {
        $api: {
            get() {
                return api;
            }
        }
    });
};

export default install;
