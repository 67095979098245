import dev from './env.dev.js';
import prod from './env.prod.js';

let env = {};
if (process.env.NODE_ENV == 'development') {
    env = dev;
}
else if (process.env.NODE_ENV == 'production') {
    env = prod;
}


export {
    
}