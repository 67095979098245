import Vue from 'vue';
import Router from 'vue-router';


// 注册单页
const chat = () => import('@/pages/chat');
const login = () => import('@/pages/login');
const body  = () => import('@/layout/default/body');
// const error = () => import('@/pages/error/404');


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

// let basePath = process.env.NODE_ENV=='development' ? '' : '/dist/admin/';
export default new Router({
    // base: basePath,
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/login',
            name: 'root',
        },
        {
            path: '/login',
            name: 'login',
            component: login,
        },
        {
            path: '/home',
            component: body,
            children: [
                {
                    path: '/chat',
                    name: 'chat',
                    component: chat,
                },
            ]
        },
    ]
})
